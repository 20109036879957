import { PrintTaskType } from './print-task-type';

export class PrintTask {
  orderId: number;
  printTaskType: PrintTaskType;
  content: string;

  static fromOther(other: PrintTask): PrintTask {
    return new PrintTask(other.orderId, other.printTaskType, other.content);
  }

  constructor(
    orderId: number,
    printTaskType: PrintTaskType,
    content?: string
  ) {
    this.orderId = orderId;
    this.printTaskType = printTaskType;
    this.content = content;
  }

  toString(): string {
    return `orderId: '${this.orderId}'. printTaskType: '${this.printTaskType}'. content: '${this.content}'`;
  }
}
