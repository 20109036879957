import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs';

import {
  VisualItem,
  VisualItemsApiName,
  AcceptedCash,
  Order,
  Product,
  Money,
  VuState,
  DtoOrder,
  PrintTask,
  DtoVuConfiguration,
  TicketUse,
  CreditCard,
  OrderType,
  Ticket,
  PaymentMethod
} from '../../../lib/lib';
import { VuHttpBaseService } from './vu-http-base.service';
import {
  API_PRINT,
  API_PRINT_TEMPLATE,
  API_SYSTEM_PRINT,
  API_SAVE_ORDER,
  API_CANCEL_ORDER,
  API_CIOBOARD_OUTPUT_PIN,
  API_UPDATE_ANGULAR_STATE,
} from './vu-http.interface';
import { ScreenSaverConfiguration } from '../../../lib/screen-saver-configuration';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TouchTileColor } from '../../../lib/touch-tile/touch-tile-color';
import { TouchTileSwitch } from '../../../lib/touch-tile/touch-tile-switch';
import { RunningLightScenario } from '../../../lib/touch-tile/running-light-scenario';
import { CreditCardTerminalState } from '../../../lib/credit-card/credit-card-terminal-state';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { CIOBoardPinEvent } from '../../../lib/cioboard/cioboard-pin-event';
import { MoneyExchangeRules } from '../../../lib/money-exchange/money-exchange-rules';
import { FmcuState } from 'src/app/modules/fmcu-access/models/fmcu-state';
import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
import { CardDispenserStatus } from 'src/app/lib/rfid-card/card-dispenser-status';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';

@Injectable()
export class VuHttpService extends VuHttpBaseService {
  private http: HttpClient;
  protected init(): void {
    this.http = this.injector.get(HttpClient);
  }

  getProducts(): Promise<Product[]> {
    return this.http
      .get('api/products')
      .toPromise()
      .then(response => this.parseProducts(response))
      .catch(this.handleError);
  }

  getProductsByIds(productIds: number[]): Promise<Product[]> {
    return this.http
      .post('api/products/products-by-ids', {
        IntArray: productIds
      })
      .toPromise()
      .then(response => this.parseProducts(response))
      .catch(this.handleError);
  }

  getProduct(productId: number): Promise<Product> {
    return this.http
      .get(`api/products/product?product_id=${productId}`)
      .toPromise()
      .then(response => Product.fromJson(response))
      .catch(this.handleError);
  }

  getSubProduct(productId: number): Promise<Product> {
    return this.http
      .get(`api/products/subproduct?product_id=${productId}`)
      .toPromise()
      .then(response => Product.fromJson(response))
      .catch(this.handleError);
  }

  calculateProductsPrice(productsInfo: any, priceListId: number): Promise<number[]> {
    return this.http
      .post(`api/products/calculate-price`, {productsInfo, priceListId})
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  getVisualItems(type: string): Promise<VisualItem[]> {
    const url = `api/${VisualItemsApiName}/?type=${type}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => this.parseVisualItems(response, type))
      .catch(this.handleError);
  }

  getDisplayConfiguration(configurationId: number): Promise<DisplayConfiguration> {
    if (!configurationId) {
      const m = `VuHttpBaseService. getDisplayConfiguration. configurationId == null`;
      this.log.error(m);
      return this.returnEmptyPromise();
    }
    const url = `api/display-items/?configuration_id=${configurationId}`;
    return this.http
      .get(url)
      .toPromise()
      .then(response => this.parseDisplayConfiguration(response))
      .catch(this.handleError);
  }

  getVuState(): Promise<VuState> {
    return this.http
      .get('api/get-vu-state')
      .toPromise()
      .then(response => {
        const vuState = VuState.fromOther(response);
        this.log.info(`VuHttpService. getVuState. ${vuState}`);
        return vuState;
      })
      .catch(this.handleError);
  }

  updateAngularPingState(): Promise<boolean> {
    return this.http
      .post(API_UPDATE_ANGULAR_STATE, {})
      .toPromise()
      .then(() => {
        this.log.info(`VuHttpService. updatePingAngularState. true`);
        return true;
      })
      .catch(response => {
        this.log.info(`VuHttpService. updatePingAngularState. False.`);
        return false;
      });
  }

  getVuConfiguration(): Promise<DtoVuConfiguration> {
    return this.http
      .get('api/get-vu-configuration')
      .toPromise()
      .then(response => {
        const c = DtoVuConfiguration.fromOther(response);
        this.log.info(`VuHttpService. getVuConfiguration. ${c}`);
        return c;
      })
      .catch(this.handleError);
  }

  getAcceptedCash(amount: Money): Promise<AcceptedCash> {
    this.log.info(`VuHttpService. getAcceptedCash. Begin. ${amount}`);
    return this.http
      .get(
        `api/payment/get-accepted-cash/?value=${amount.value}&currencyCode=${amount.currencyCode
        }`
      )
      .toPromise()
      .then(response => {
        const result = this.parseAcceptedCash(response);
        this.log.info(`VuHttpService. getAcceptedCash.  End. ${result}`);
        return result;
      })
      .catch(this.handleError);
  }

  canPayoutAmount(amount: Money): Promise<boolean> {
    this.log.info(`VuHttpService. canPayoutAmount. Begin. ${amount}`);
    return this.http
      .get(
        `api/payment/can-payout-amount/?value=${amount.value}&currencyCode=${amount.currencyCode
        }`
      )
      .toPromise()
      .then(response => {
        this.log.info(`VuHttpService. canPayoutAmount. True.  End. `);
        return true;
      })
      .catch(response => {
        this.log.info(`VuHttpService. canPayoutAmount. False. End. `);
        return false;
      });
  }

  howMuchCanPayoutAmount(amount: Money): Promise<Money> {
    this.log.info(`VuHttpService. canPayoutAmount. Begin. ${amount}`);
    return this.http
      .get(
        `api/payment/how-much-can-payout-amount/?value=${amount.value}&currencyCode=${amount.currencyCode
        }`
      )
      .toPromise()
      .then(response => {
        this.log.info(`VuHttpService. canPayoutAmount. True.  End. `);
        return Money.fromJSON(response);
      })
      .catch(response => {
        this.log.info(`VuHttpService. canPayoutAmount. False. End. `);
        return Money.empty;
      });
  }

  getTicketUse(code: string): Promise<TicketUse[]> {
    return this.http
      .get(`api/ticket/get-ticket-use/?code=${code}`)
      .toPromise()
      .then(response => {
        const result = this.parseTicketUse(response);
        this.log.info(
          `VuHttpService. getTicketUse. ${TicketUse.toString(result)}`
        );
        return result;
      })
      .catch(this.handleError);
  }

  beginPaymentTransaction(amount: Money, paymentMethod: PaymentMethod, giftName: string, giftAmount: Money): Promise<void> {
    super.beginPaymentTransaction(amount, paymentMethod, giftName, giftAmount);
    return this.http
      .post(`api/payment/begin-transaction`, {
        money: {
          value: `${amount.value}`,
          currencyCode: `${amount.currencyCode}`
        },
        paymentMethod,
        barcode: giftName,
        giftAmount: {
          value: giftAmount ? `${giftAmount.value}` : '',
          currencyCode: giftAmount ? `${giftAmount.currencyCode}` : ''
        },
      })
      .toPromise()
      .catch(this.handleError);
  }

  returnAmount(amount: Money): Promise<any> {
    super.returnAmount(amount);
    return this.http
      .post(`api/payment/return-amount`, {
        Value: `${amount.value}`,
        CurrencyCode: `${amount.currencyCode}`
      })
      .toPromise()
      .catch(this.handleError);
  }

  commitPaymentTransaction(force: boolean): Promise<void> {
    super.commitPaymentTransaction(force);
    return this.http
      .post(`api/payment/commit-transaction`, {
        BoolValue: force
      })
      .toPromise()
      .catch(this.handleError);
  }

  appendTransactionInfo(info: string): Promise<void> {
    super.appendTransactionInfo(info);
    return this.http
      .post(`api/payment/append-transaction-info`, { info })
      .toPromise()
      .catch(this.handleError);
  }

  revertPaymentTransaction(): Promise<any> {
    super.revertPaymentTransaction();
    return this.http.post(`api/payment/revert-transaction`, {}).toPromise();
  }

  rfidCardLedEnable(value: boolean): void {
    super.rfidCardLedEnable(value);
    this.http
      .post(`api/touch-tile/rfid-card-led-enable`, { enabled: value })
      .toPromise()
      .catch(this.handleError);
  }

  revertCashlessTransaction(paymentMethod: PaymentMethod): Promise<any> {
    super.revertCashlessTransaction(paymentMethod);
    return this.http.post(`api/payment/revert-cashless-transaction`, {paymentMethod}).toPromise();
  }

  abortCashlessTransaction(paymentMethod: PaymentMethod): Promise<any> {
    super.abortCashlessTransaction(paymentMethod);
    return this.http.post(`api/payment/abort-cashless-transaction`, {paymentMethod}).toPromise();
  }

  rfidCardReaderEnable(value: boolean): void {
    super.rfidCardLedEnable(value);
    this.http
      .post(`api/rfid-reader/${value ? 'start' : 'stop'}`, {})
      .toPromise()
      .catch(this.handleError);
  }

  barcodeLedEnable(value: boolean): void {
    super.barcodeLedEnable(value);
    this.http
      .post(`api/touch-tile/barcode-led-enable`, { enabled: value })
      .toPromise()
      .catch(this.handleError);
  }

  saveOrder(order: Order): Promise<any> {
    super.saveOrder(order);
    const dtoOrder = DtoOrder.fromOrder(order);
    return this.http
      .post(API_SAVE_ORDER, JSON.stringify(dtoOrder))
      .toPromise()
      .catch(this.handleError);
  }

  cancelOrder(order: Order): Promise<any> {
    super.cancelOrder(order);
    order.convertToRefundType();
    const dtoOrder = DtoOrder.fromOrder(order);
    return this.http
      .post(API_CANCEL_ORDER, JSON.stringify(dtoOrder))
      .toPromise()
      .catch(this.handleError);
  }

  resetOrderRfidCardAccessData(orderId: number, cardNumber: string): Promise<any> {
    super.resetOrderRfidCardAccessData(orderId, cardNumber);
    return this.http
      .post(`api/reset-order-rfid-card-access-data`, { orderId, cardNumber })
      .toPromise()
      .catch(this.handleError);
  }

  callStaff(): void {
    super.callStaff();
    this.http
      .post(`api/call-staff`, {})
      .toPromise()
      .catch(this.handleError);
  }

  print(task: PrintTask): Promise<any> {
    super.print(task);
    return this.http
      .post(API_PRINT, JSON.stringify(task))
      .toPromise()
      .catch(this.handleError);
  }

  printByTemplateTypeUniqueName(templateTypeUniqueName: string, language: string): Promise<any> {
    super.printByTemplateTypeUniqueName(templateTypeUniqueName, language);
    return this.http
      .post(API_PRINT_TEMPLATE, { templateUniqueName: templateTypeUniqueName, language })
      .toPromise()
      .catch(this.handleError);
  }

  printApi(): Promise<any> {
    super.printApi();
    return this.http
      .post(API_SYSTEM_PRINT, {})
      .toPromise()
      .catch(this.handleError);
  }

  getPrintXmlTemplate(task: PrintTask): Promise<string> {
    super.print(task);
    return this.http
      .post('api/print/get-xml-template', JSON.stringify(task))
      .toPromise()
      .then((response: any) => response?.xml || '')
      .catch(this.handleError);
  }

  openFmcuApi(url: string, body: object = {}): Promise<any> {
    super.openFmcuApi(url, body);
    return this.http
      .post(url, body)
      .toPromise()
      .catch(this.handleError);
  }

  scanTicket(barcode: string): void {
    super.scanTicket(barcode);
    this.http.post('api/ticket/scan', { code: barcode })
      .toPromise()
      .catch(this.handleError);
  }

  printTicket(barcode: string): Promise<any> {
    super.scanTicket(barcode);
    return this.http.post('api/ticket/print', { Code: barcode })
      .toPromise()
      .catch(this.handleError);
  }

  getScreenSaverConfiguration(): Promise<ScreenSaverConfiguration> {
    return this.http
      .get(`api/screensaver/settings`)
      .toPromise()
      .then(response => ScreenSaverConfiguration.fromJson(response))
      .catch(this.handleError);
  }

  openEnter(): Observable<boolean> {
    return this.http.get(`/api/turnstile/open_enter`).pipe(
      map(result => {
        return true;
      })
    );
  }

  closeEnter(): Observable<boolean> {
    return this.http.get(`/api/turnstile/close`).pipe(
      map(result => {
        return true;
      })
    );
  }

  demoPayment(): Observable<any> {
    return this.http.post(`/api/payment/demo-payment`, {}).pipe(
      map(result => {
        return true;
      })
    );
  }

  changeTouchTileColor(touchTileColor: TouchTileColor[]): Observable<boolean> {
    super.changeTouchTileColor(touchTileColor);
    return this.http
      .post('api/touch-tile/change-color', JSON.stringify(touchTileColor))
      .pipe(
        map(result => {
          return true;
        })
      );
  }

  changeTouchTileSwitch(
    touchTileSwitch: TouchTileSwitch[]
  ): Observable<boolean> {
    super.changeTouchTileSwitch(touchTileSwitch);
    return this.http
      .post('api/touch-tile/change-switch', JSON.stringify(touchTileSwitch))
      .pipe(
        map(result => {
          return true;
        })
      );
  }

  changeTouchTileManualMode(enabled: boolean): Observable<boolean> {
    super.changeTouchTileManualMode(enabled);
    return this.http
      .post('api/touch-tile/manual-mode-enable', { enabled })
      .pipe(
        map(result => {
          return true;
        })
      );
  }

  setRunningLight(
    runningLightScenario: RunningLightScenario
  ): Observable<boolean> {
    super.setRunningLight(runningLightScenario);
    return this.http
      .post('api/touch-tile/set-running-light', runningLightScenario)
      .pipe(
        map(result => {
          return true;
        })
      );
  }

  switchLntBoard(enabled: boolean): void {
    super.switchLntBoard(enabled);
    this.http
      .post('api/touch-tile/switch-lnt-board', { enabled })
      .toPromise()
      .catch(this.handleError);
  }

  getAcceptedCreditCards(): Promise<CreditCard[]> {
    this.log.info(`VuHttpService. getAcceptedCreditCards. Begin.`);
    return this.http
      .get(`/api/credit-card-terminal/accepted-credit-cards`)
      .toPromise()
      .then(response => {
        const result = this.parseAcceptedCreditCards(response);
        this.log.info(`VuHttpService. getAcceptedCreditCards. End. ${result?.join(', ')}`);
        return result;
      })
      .catch(this.handleError);
  }

  getCreditCardTerminalState(): Observable<CreditCardTerminalState> {
    super.getCreditCardTerminalState();
    return this.http
      .get('api/credit-card-terminal/state').pipe(
        map((x: any) => CreditCardTerminalState.fromJson(x)),
        catchError(this.handleError),
      );
  }
  getTicketInfo(barcode: string): Observable<Ticket> {
    super.getTicketInfo(barcode);
    return this.http.get(`api/ticket/get-ticket-info?code=${encodeURIComponent(barcode)}`)
      .pipe(
        map(result => {
          return Ticket.fromJson(result);
        })
      );
  }

  activateOneDayTicket(ticketCode: string): Observable<Ticket> {
    super.activateOneDayTicket(ticketCode);
    return this.http.post('api/ticket/activate-one-day', { Code: ticketCode })
      .pipe(
        map(result => {
          return Ticket.fromJson(result);
        })
      );
  }

  gateReadBarcode(barcode: string, baseUrl: string, openGateType: string, timeout: number): Observable<any> {
    super.gateReadBarcode(barcode, baseUrl, openGateType, timeout);
    return this.http.post(`api/gate/read-barcode`, {
      barcode,
      baseUrl,
      openGateType,
      timeout
    });
  }

  changeServerLanguage(localeId: string): void {
    super.changeServerLanguage(localeId);
    this.http
      .post(`/api/language/change`, {  locale_id: localeId })
      .toPromise()
      .catch(this.handleError);
  }

  cioBoardAction(pins: CIOBoardPinEvent[]): Promise<any> {
    super.cioBoardAction(pins);
    return this.http
      .post(API_CIOBOARD_OUTPUT_PIN, JSON.stringify(pins))
      .toPromise()
      .catch(this.handleError);
  }

  writeLogMessages(messages: string[]): Promise<any> {
    return this.http
      .post(`api/browser-log`, { messages })
      .toPromise()
      .catch(this.handleError);
  }

  produceRfidCard(): Promise<any> {
    super.produceRfidCard();
    return this.http.post('api/rfid-card/produce', {})
      .toPromise()
      .catch(this.handleError);
  }

  releaseRfidCard(): Promise<any> {
    super.releaseRfidCard();
    return this.http.post('api/rfid-card/release', {})
      .toPromise()
      .catch(this.handleError);
  }

  startTakingRfidCard(): Promise<any> {
    super.startTakingRfidCard();
    return this.http.post('api/rfid-card/start-taking', {})
      .toPromise()
      .catch(this.handleError);
  }

  stopTakingRfidCard(): Promise<any> {
    super.stopTakingRfidCard();
    return this.http.post('api/rfid-card/stop-taking', {})
      .toPromise()
      .catch(this.handleError);
  }

  captureRfidCard(): Promise<any> {
    super.captureRfidCard();
    return this.http.post('api/rfid-card/capture', {})
      .toPromise()
      .catch(this.handleError);
  }

  cardDispenserStatus(): Observable<CardDispenserStatus> {
    return this.http.get('api/rfid-card/status').pipe(
      map(status => {
        return CardDispenserStatus.createFromAny(status);
      })
    );
  }

  writeRfidCardData(rfidCardData: RfidCardData): Promise<any> {
    super.writeRfidCardData(rfidCardData);
    return this.http.post('api/rfid-card/write-card-data', rfidCardData?.data)
      .toPromise()
      .catch(this.handleError);
  }

  readRfidCardData(rfidCardData: RfidCardData): Promise<any> {
    super.writeRfidCardData(rfidCardData);
    return this.http.post('api/rfid-card/read-card-data', rfidCardData?.data)
      .toPromise()
      .catch(this.handleError);
  }

  startTransaction(): Promise<any> {
    super.startTransaction();
    return this.http.post('api/rfid-card/start-transaction', {})
      .toPromise()
      .catch(this.handleError);
  }

  stopTransaction(): Promise<any> {
    super.stopTransaction();
    return this.http.post('api/rfid-card/stop-transaction', {})
      .toPromise()
      .catch(this.handleError);
  }

  isCardTerminalAvailable(): Observable<boolean> {
    super.isCardTerminalAvailable();
    return this.http.get(`api/credit-card-terminal/is-available`)
      .pipe(
        map(result => {
          return result as boolean;
        })
      );
  }

  getMoneyExchangeRules(): Observable<MoneyExchangeRules> {
    this.log.info(`VuHttpService. getMoneyRulesForExchange. Begin.`);
    return this.http.get(`api/money-exchange/rules`)
      .pipe(
        map(response => {
          const result = MoneyExchangeRules.fromJson(response);
          this.log.info(`VuHttpService. getMoneyRulesForExchange.  End.`);
          return result;
        }),
        catchError(this.handleError),
      );
  }

  isMoneyExchangeTransactionExist(): Observable<boolean> {
    this.log.info(`VuHttpService. getIsTransactionStarted. Begin.`);
    return this.http.post(`api/money-exchange/check-transaction-exist`, JSON.stringify({}))
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. getIsTransactionStarted.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  beginMoneyExchangeTransaction(acceptedCash: AcceptedCash): Observable<boolean> {
    this.log.info(`VuHttpService. beginMoneyExchangeTransaction. Begin.`);
    return this.http.post(`api/money-exchange/begin-transaction`, JSON.stringify(acceptedCash))
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. beginMoneyExchangeTransaction.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  rollbackMoneyExchangeTransaction(): Observable<boolean> {
    this.log.info(`VuHttpService. rollbackMoneyExchangeTransaction. Begin.`);
    return this.http.post(`api/money-exchange/rollback-transaction`, {})
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. rollbackMoneyExchangeTransaction.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  commitMoneyExchangeTransaction(): Observable<boolean> {
    this.log.info(`VuHttpService. commitMoneyExchangeTransaction. Begin.`);
    return this.http.post(`api/money-exchange/commit-transaction`, {})
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. commitMoneyExchangeTransaction.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  moneyExchangePayoutMoney(payoutAcceptedCash: AcceptedCash): Observable<boolean> {
    this.log.info(`VuHttpService. moneyExchangePayoutMoney. Begin.`);
    return this.http.post(`api/money-exchange/payout-money`, JSON.stringify(payoutAcceptedCash))
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. moneyExchangePayoutMoney.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  beginUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    this.log.info(`VuHttpService. beginUnrestrictedCashPaymentTransaction. Begin.`);
    return this.http.post(`api/unrestricted-cash-payment/begin-transaction`, {})
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. beginUnrestrictedCashPaymentTransaction.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  commitUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    this.log.info(`VuHttpService. commitUnrestrictedCashPaymentTransaction. Begin.`);
    return this.http.post(`api/unrestricted-cash-payment/commit-transaction`, {})
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. commitUnrestrictedCashPaymentTransaction.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  resetUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    this.log.info(`VuHttpService. resetUnrestrictedCashPaymentTransaction. Begin.`);
    return this.http.post(`api/unrestricted-cash-payment/reset-transaction`, {})
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. resetUnrestrictedCashPaymentTransaction.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  getAcceptedCashWithoutRestrictions(): Observable<AcceptedCash> {
    this.log.info(`VuHttpService. getAcceptedCashWithoutRestrictions. Begin.`);
    return this.http.get(`api/unrestricted-cash-payment/accepted-cash`)
      .pipe(
        map(response => {
          const result = AcceptedCash.fromJson(response);
          this.log.info(`VuHttpService. getAcceptedCashWithoutRestrictions.  End.`);
          return result;
        }),
        catchError(this.handleError),
      );
  }

  getOrderCache(): Observable<AcceptedCash> {
    this.log.info(`VuHttpService. getOrderCache. Begin.`);
    return this.http.get(`api/order-cache`)
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. getOrderCache.  End.`);
          return response;
        }),
        catchError(this.handleError),
      );
  }

  getFmcuState(baseUrl: string): Observable<FmcuState> {
    this.log.info(`VuHttpService. getFmcuState. Begin.`);
    return this.http.post(`api/gate/state`, { baseUrl })
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. getFmcuState.  End.`);
          return FmcuState.createFromAny(response);
        }),
        catchError(this.handleError),
      );
  }

  gateClose(baseUrl: string): Observable<any> {
    super.gateClose(baseUrl);
    return this.http.post(`api/gate/close`, { baseUrl }).pipe(
      catchError(this.handleError),
    );
  }

  getCardPaymentInformation(code: string): Observable<CardInformation> {
    this.log.info(`VuHttpService. getCardPaymentInformation. Begin.`);
    return this.http.post(`api/gift-card/card-info`, { stringValue: code })
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. getCardPaymentInformation.  End.`);
          return CardInformation.createFromAny(response);
        }),
        catchError(this.handleError),
      );
  }

  cardPaymentScan(code: string, amount: number): Observable<boolean> {
    this.log.info(`VuHttpService. getCardPaymentScan. Begin.`);
    return this.http.post(`api/gift-card/card-scan`, { stringValue: code, decimalValue: amount || 0 })
      .pipe(
        map(response => {
          this.log.info(`VuHttpService. getCardPaymentScan.  End.`);
          return true;
        }),
        catchError(this.handleError),
      );
  }
}
