import { Component, OnInit, OnDestroy, ViewEncapsulation, Injector, LOCALE_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { Ticket, TicketStatus, Product } from '../../../lib/lib';
import { StatusInfo } from '../../../lib/status-info';
import { DispatcherService } from '../../../services/dispatcher.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { LoggingService } from '../../../services/logging/logging.service';
import { SaleService } from '../../../services/sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { LanguageService, getLanguageFactory } from '../../../services/language.service';

@Component({
  moduleId: module.id,
  selector: 'ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TicketInfoComponent implements OnInit, OnDestroy {
  private subscriptionTicket: Subscription;
  private dispatcherService: DispatcherService;
  private vuCommunicationService: VuCommunicationService;
  private log: LoggingService;
  private saleService: SaleService;
  protected configurationService: ConfigurationService;
  ticket = new Ticket();
  product: Product = null;
  subProduct: Product = null;
  isBackButtonVisible: boolean;

  constructor(
    private injector: Injector,
  ) {
    this.log = this.injector.get(LoggingService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.saleService = this.injector.get(SaleService);
    this.configurationService = this.injector.get(ConfigurationService);
  }

  ngOnInit(): void {
    this.dispatcherService.pageHeaderSet('Ticket Information');
    this.subscriptionTicket = this.dispatcherService.onTicketChangedSubscribe(x => this.onTicketChanged(x));
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.subscriptionTicket.unsubscribe();
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
  }

  private onTicketChanged(ticket: Ticket): void {
    this.subProduct = null;
    this.ticket = ticket;
    if (!ticket.isUnknown) {
      this.updateProduct(ticket.productId);
    }
  }

  onPayClick() {
    if (this.subProduct == null) {
      return;
    }

    const ticket = this.ticket;
    if (ticket == null) {
      return;
    }

    const quantity = Math.floor(ticket.outstandingOvertime / this.subProduct.accessTimeMinutes) + 1;
    if (!quantity) {
      return;
    }

    const order = this.saleService.order;
    const orderLine = order.addProduct(this.subProduct, quantity, null, ticket.code);
    orderLine.minQuantity = quantity;
    orderLine.maxQuantity = quantity;
    order.isPrefilledOrder = true;
    this.dispatcherService.ticketOvertimePayment();
  }

  get isRoleExit(): boolean {
    return this.configurationService.isRoleExit;
  }

  private updateProduct(productId: number) {
    if (productId == null || productId === 0) { return; }

    this.vuCommunicationService.vuHttp.getSubProduct(productId)
      .then(subProduct => {
        this.subProduct = subProduct;
        this.log.info(`TicketInfoComponent. subProduct: ${subProduct}`);
      });

    this.vuCommunicationService.vuHttp.getProduct(productId)
      .then(product => {
        this.product = product;
        this.log.info(`TicketInfoComponent. product: ${product}`);
      });
  }

  onTicketUseClick() {
    if (this.ticket != null) {
      this.dispatcherService.ticketUse(this.ticket);
    }
  }

  onReturnClick() {
    this.saleService.ticketReturnAmount(this.product, this.ticket.amount, this.ticket.orderId, this.ticket.code, false);
  }

  get isTicketHistoryButtonVisible(): boolean {
    return this.ticket && !this.ticket.isUnknown && !this.ticket.isVirtualTicket
    && (!this.showTicketStatus || this.ticket.quantity > 1);
  }

  get showTicketStatus(): boolean {
    return this.ticket
      && this.ticket.validFromExist
      && this.ticket.validToExist
      && this.ticket.validFrom.getFullYear() === this.ticket.validTo.getFullYear()
      && this.ticket.validFrom.getMonth() === this.ticket.validTo.getMonth()
      && this.ticket.validFrom.getDate() === this.ticket.validTo.getDate();
  }
}
