import { Injectable, Injector, EventEmitter } from '@angular/core';
import { LoggingService } from './../logging/logging.service';
import { Observable } from 'rxjs';
import { AcceptedCash, Money } from '../../lib/lib';
import { MoneyExchangeRules } from '../../lib/money-exchange/money-exchange-rules';
import { MoneyExchangeRule } from '../../lib/money-exchange/money-exchange-rule';
import { MoneyExchangeService } from './money-exchange.service';
import { map } from 'rxjs/operators';


@Injectable()
export class MoneyExchangeStateService {

  private loggingService: LoggingService;
  private moneyExchangeService: MoneyExchangeService;

  private _enabled: boolean;
  private _rules = new Map<number, MoneyExchangeRule>();
  private _insertedMoney: Money;
  private _payoutAmount: Money;
  private _acceptedCash: AcceptedCash;
  private _returnMoney: boolean;

  public eventMoneyPayin: EventEmitter<Money> = new EventEmitter<Money>(true);
  public eventMoneyPayout: EventEmitter<Money> = new EventEmitter<Money>(true);
  public eventPayoutMoneyStart: EventEmitter<AcceptedCash> = new EventEmitter<AcceptedCash>(true);
  public eventReadyToMoneyExchange: EventEmitter<any> = new EventEmitter<any>(true);

  constructor(
    private injector: Injector,
  ) {
    this.loggingService = this.injector.get(LoggingService);
    this.moneyExchangeService = this.injector.get(MoneyExchangeService);
    this.moneyExchangeService.eventMoneyPayin.subscribe(money => this.onMoneyPayin(money));
    this.moneyExchangeService.eventMoneyPayout.subscribe(money => this.onMoneyPayout(money));
    this.moneyExchangeService.eventReadyToMoneyExchange.subscribe(money => this.onReadyToMoneyExchange());
  }

  public get isEnabled(): boolean {
    return this._enabled;
  }

  public set isEnabled(value: boolean) {
    this.loggingService.info(`Money Exchange Enabled: ${value}`);
    this._enabled = value;
  }

  public get isReturnMoney(): boolean {
    return this._returnMoney;
  }

  public get acceptedCash(): AcceptedCash {
    return this._acceptedCash;
  }

  public get insertedMoney(): Money {
    return this._insertedMoney;
  }

  public get returnedAmount(): Money {
    return this._payoutAmount;
  }

  public initialize(): Observable<boolean> {
    this.loggingService.info(`Money Exchange Service. initialize..`);
    return this.moneyExchangeService.getMoneyExchangeRules().pipe(
      map(
        result => {
          this.loggingService.info(`Money Exchange Service. getMoneyExchangeRules. Rules: ${result}`);
          this._reset(result);
          return true;
        })
    );
  }

  private _reset(rules: MoneyExchangeRules): void {
    this._reloadRules(rules);
    this._reloadAcceptedCash(rules);
    this._payoutAmount = Money.empty;
    this._insertedMoney = Money.empty;
    this._returnMoney = false;
  }

  private _reloadAcceptedCash(rules: MoneyExchangeRules): void {
    if (rules) {
      this._acceptedCash = new AcceptedCash(
        rules.coins ? rules.coins.filter(item => !item.isEmpty).map(item => item.from) : new Money[0],
        rules.banknotes ? rules.banknotes.filter(item => !item.isEmpty).map(item => item.from) : new Money[0]
      );
    } else {
      this._acceptedCash = null;
    }

    this.loggingService.info(`Money Exchange Service. reloadAcceptedCash. AcceptedCash: ${this._acceptedCash} `);
  }

  private _reloadRules(rules: MoneyExchangeRules): void {
    this._rules.clear();
    if (rules) {
      if (rules.coins) {
        rules.coins.forEach(element => {
          this._rules.set(element.from.value, element);
        });
      }
      if (rules.banknotes) {
        rules.banknotes.forEach(element => {
          this._rules.set(element.from.value, element);
        });
      }
    }
  }

  public isTransactionExist(): Observable<boolean> {
    this.loggingService.info(`Money Exchange Service. isTransactionExist`);
    return this.moneyExchangeService.isTransactionExist();
  }

  public beginTransaction(): Observable<boolean> {
    this.loggingService.info(`Money Exchange Service. beginTransaction. AcceptedCash: ${this._acceptedCash}`);
    return this.moneyExchangeService.beginTransaction(this._acceptedCash);
  }

  public commitTransaction(): Observable<boolean> {
    this.loggingService.info(`Money Exchange Service. commitTransaction.`);
    return this.moneyExchangeService.commitTransaction();
  }

  public rollbackTransaction(): Observable<boolean> {
    this.loggingService.info(`Money Exchange Service. rollbackTransaction.`);
    this._returnMoney = true;
    return this.moneyExchangeService.rollbackTransaction();
  }

  private onMoneyPayin(money: Money) {
    this.loggingService.info(`Money Exchange Service. onMoneyPayin: ${money || Money.empty}`);
    this._insertedMoney = money;
    this.eventMoneyPayin.emit(money);
  }

  private onMoneyPayout(money: Money) {
    if (this._returnMoney) {
      this.loggingService.info(`Money Exchange Service. onMoneyPayout. return: ${money || Money.empty}`);
    } else {
      this._payoutAmount = this._payoutAmount.add(money);
      this.loggingService.info(`Money Exchange Service. onMoneyPayout. payin: ${this._insertedMoney || Money.empty}, total payout: ${this._payoutAmount || Money.empty}, current: ${money || Money.empty}`);
    }
    this.eventMoneyPayout.emit(money);
  }

  private onReadyToMoneyExchange() {
    this.loggingService.info(`Money Exchange Service. onReadyToMoneyExchange.`);
    this.eventReadyToMoneyExchange.emit();
  }

  public get variants(): AcceptedCash[] {
    if (!this._insertedMoney) {
      return [];
    }

    const rule = this._rules.get(this._insertedMoney.value);
    if (rule) {
      return rule.to;
    }

    return [];
  }

  public payoutMoney(payoutAcceptedCash: AcceptedCash) {
    this._returnMoney = false;
    this.loggingService.info(`Money Exchange Service. payoutMoney. payoutAcceptedCash: ${payoutAcceptedCash}`);
    this.eventPayoutMoneyStart.emit(payoutAcceptedCash);
    this.moneyExchangeService.payoutMoney(payoutAcceptedCash).subscribe();
  }
}

