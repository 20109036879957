import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GiftCardPaymentButtonType } from 'src/app/lib/gift-card/gift-card-payment-button-type';
import { PaymentMethod } from 'src/app/lib/payment/payment-method';
import { PaymentMethodUtils } from 'src/app/lib/payment/payment-method-utils';
import { AdditionalPropertiesConfigurationService } from 'src/app/services/configuration/additional-properties-configuration.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';
import { SaleService } from '../sale.service';

@Injectable()
export class PaymentsMethodService {

  private _isCardTerminalAvailable: boolean;
  private ignoreOrderAmount: boolean;

  constructor(
    private configurationService: ConfigurationService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
    private vuCommunicationService: VuCommunicationService,
    private saleService: SaleService,
  ) {
  }

  initialize(ignoreOrderAmount: boolean): Observable<boolean> {
    return this.vuCommunicationService.vuHttp.isCardTerminalAvailable()
      .pipe(
        tap(isAvailable => this._isCardTerminalAvailable = isAvailable),
        tap(isAvailable => this.ignoreOrderAmount = ignoreOrderAmount),
      );
  }

  get isCreditCardPaymentAvailable(): boolean {
    return this._isCardTerminalAvailable
      && this.additionalPropertiesConfigurationService
      && this.additionalPropertiesConfigurationService.isCreditCardPaymentAvailable
      // && !this.additionalPropertiesConfigurationService.isLiteMode
      && (this.orderAmount > 0 || this.ignoreOrderAmount)
      && this.isPaymentMethodAvailable(PaymentMethod.PaymentCard);
  }

  get orderAmount(): number {
    if (this.saleService.order && this.saleService.order.amountTotal) {
      return this.saleService.order.amountTotal.value;
    }
    return 0;
  }

  get hasOrderlineZeroQuantity(): boolean {
    return this.saleService.order
    && this.saleService.order.hasZeroQuantity;
  }

  get isCashPaymentAvailable(): boolean {
    return this.additionalPropertiesConfigurationService
      && this.additionalPropertiesConfigurationService.isCashPaymentAvailable
      && this.isPaymentMethodAvailable(PaymentMethod.Cash);
  }

  isPaymentMethodAvailable(paymentMethod: PaymentMethod): boolean {
    return this.configurationService.configuration
      && this.configurationService.configuration.paymentMethods
      && this.configurationService.configuration.paymentMethods.some(x => x === paymentMethod);
  }

  get isBluecodePaymentAvailable(): boolean {
    return this.additionalPropertiesConfigurationService
      && this.additionalPropertiesConfigurationService.isBluecodePaymentAvailable
      && this.isPaymentMethodAvailable(PaymentMethod.Bluecode);
  }

  get isGiftcardPaymentAvailable(): boolean {
    return !this.saleService.order.giftCardPartialPaymentAmount
      && (this.orderAmount > 0 || this.ignoreOrderAmount)
        && this.isPaymentMethodAvailable(PaymentMethod.GiftCard);
  }

  getImageUrl(paymentMethod: PaymentMethod): string {

    if (paymentMethod === PaymentMethod.PaymentCard) {
      return '/static/images/svg/payment/black/credit-card.svg';
    }

    if (paymentMethod === PaymentMethod.Cash) {
      return '/static/images/svg/payment/black/money.svg';
    }

    if (paymentMethod === PaymentMethod.Bluecode) {
      return '/static/images/svg/payment/black/bluecode.svg';
    }

    if (paymentMethod === PaymentMethod.GiftCard) {
      if (this.configurationService.configuration.giftCardPaymentButtonType === GiftCardPaymentButtonType.NfcCard) {
        return '/static/images/svg/payment/black/nfc.svg';
      }
      return '/static/images/svg/payment/black/giftcard.svg';
    }

    return '';
  }

  showProviderName(paymentMethod: PaymentMethod): boolean {
    if (this.getImageUrl(paymentMethod)) {
      return false;
    }

    return true;
  }

  getProviderName(paymentMethod: PaymentMethod): string {

    if (paymentMethod === PaymentMethod.PaymentCard) {
      return 'Bank Card';
    }

    if (paymentMethod === PaymentMethod.Cash) {
      return 'Cash';
    }

    if (paymentMethod === PaymentMethod.Bluecode) {
      return '';
    }

    if (paymentMethod === PaymentMethod.GiftCard) {
      if (this.configurationService.configuration.giftCardPaymentButtonType === GiftCardPaymentButtonType.NfcCard) {
        return 'NFC-Card';
      }
      return 'Gift Card';
    }

    return '';
  }

  getButtonText(paymentMethod: PaymentMethod): string {

    if (paymentMethod === PaymentMethod.PaymentCard) {
      return 'Payment by card';
    }

    if (paymentMethod === PaymentMethod.Cash) {
      return 'Payment in cash';
    }

    if (paymentMethod === PaymentMethod.Bluecode) {
      return 'Bluecode';
    }

    if (paymentMethod === PaymentMethod.GiftCard) {
      if (this.configurationService.configuration.giftCardPaymentButtonType === GiftCardPaymentButtonType.NfcCard) {
        return 'NFC-Card';
      }
      return 'Payment by gift card';
    }

    return '';
  }

  getIconClass(paymentMethod: PaymentMethod): string {

    if (paymentMethod === PaymentMethod.PaymentCard) {
      return 'fa fa-credit-card';
    }

    if (paymentMethod === PaymentMethod.Cash) {
      return 'fa fa-money';
    }

    if (paymentMethod === PaymentMethod.Bluecode) {
      return 'fa fa-credit-card';
    }

    if (paymentMethod === PaymentMethod.GiftCard) {
      return 'fa fa-credit-card';
    }

    return '';
  }

  private get isNeedAddCashPayment(): boolean {
    return !this.additionalPropertiesConfigurationService?.isLiteMode;
  }

  get availablePaymentMethods(): PaymentMethod[] {

    const availablePayments = [];

    if (this.isCashPaymentAvailable
      && this.isPaymentMethodVisible(PaymentMethod.Cash)
      && this.isNeedAddCashPayment) {
      availablePayments.push(PaymentMethod.Cash);
    }

    if (this.isGiftcardPaymentAvailable
      && !this.configurationService.configuration.giftCardSeparateButton
      && this.isPaymentMethodVisible(PaymentMethod.GiftCard)) {
      availablePayments.push(PaymentMethod.GiftCard);
    }

    if (this.isCreditCardPaymentAvailable
      && this.isPaymentMethodVisible(PaymentMethod.PaymentCard)) {
      availablePayments.push(PaymentMethod.PaymentCard);
    }

    if (this.isBluecodePaymentAvailable
      && this.isPaymentMethodVisible(PaymentMethod.Bluecode)) {
      availablePayments.push(PaymentMethod.Bluecode);
    }

    return availablePayments;
  }

  isPaymentMethodVisible(paymentMethod: PaymentMethod): boolean {

    const hiddenPaymentJournalCodes = this.configurationService?.configuration?.originalConfigurationHelper?.getStringArray('hidden_payment_journal_codes');
    if (!hiddenPaymentJournalCodes) {
      return true;
    }

    return !hiddenPaymentJournalCodes.map(item => PaymentMethodUtils.journalNameToPaymentMethod(item)).some(item => item === paymentMethod);
  }
}
