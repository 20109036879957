import { Component, OnInit, OnDestroy } from '@angular/core';
import { MoneyExchangeStateService } from '../../../services/money-exchange/money-exchange-state.service';
import { CashType, AcceptedCash } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  selector: 'app-exchange-rules',
  templateUrl: './exchange-rules.component.html',
  styleUrls: ['./exchange-rules.component.css']
})
export class ExchangeRulesComponent implements OnInit, OnDestroy {

  private backButtonVisible: boolean;

  constructor(
    private moneyExchangeStateService: MoneyExchangeStateService,
    private dispatcherService: DispatcherService,
  ) { }

  ngOnInit() {
    this.dispatcherService.pageHeaderSet('Money Exchange');
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
  }

  get variants(): AcceptedCash[] {
    return this.moneyExchangeStateService.variants || [];
  }

  public get insertedMoney(): string {
    if (this.moneyExchangeStateService.insertedMoney) {
      return this.moneyExchangeStateService.insertedMoney.toStringCurrencySign();
    }
    return '';
  }

  get coinType(): CashType {
    return CashType.Coin;
  }

  get billsType(): CashType {
    return CashType.Banknote;
  }

  onChoice(variant: AcceptedCash) {
    this.moneyExchangeStateService.payoutMoney(variant);
  }
}
