import { Action } from '@ngrx/store';
import { Teaser, TeaserType } from '../lib/lib';
import { PayloadAction } from '../lib/payload-action';

const initialState = new Teaser();

export class TeaserActionType {
  static readonly TEASER_RESET = 'TeaserReset';
  static readonly TEASER_ADD_ITEM = 'TeaserAddItem';
  static readonly TEASER_DELETE_ITEM = 'TeaserDeleteItem';
}

export function teaserReducer(state = initialState, action: PayloadAction) {
  let isChanged = true;
  switch (action.type) {
    case TeaserActionType.TEASER_RESET:
      state = action.payload;
      break;
    case TeaserActionType.TEASER_ADD_ITEM:
      if (!state.items || state.items.length === 0 || action.payload !== TeaserType.Thanks) {
        state.addItem(action.payload);
      }
      break;
    case TeaserActionType.TEASER_DELETE_ITEM:
      state.deleteItem(action.payload);
      break;
    default:
      isChanged = false;
      break;
  }
  return isChanged ? Teaser.fromOther(state) : state;
}
