import { Injectable, Injector, EventEmitter } from '@angular/core';
import { Money, CreditCard, DtoVuConfiguration, PaymentMethod } from '../../lib/lib';
import { BannerConfiguration } from '../../lib/lite/banner-configuration';
import { SaleService } from '../sale.service';
import { LiteDisplayStepsService } from './lite-display-steps.service';
import { StepConfigurationType } from '../../lib/lite/step-configuration-type';
import { DispatcherService } from '../dispatcher.service';
import { VuCommunicationService } from '../vu/vu-communication.service';
import { AdditionalPropertiesConfigurationService } from '../configuration/additional-properties-configuration.service';
import { DemoPaymentService } from '../payment/demo-payment.service';
import { from, Observable, of, Subscription } from 'rxjs';
import { PaymentsMethodService } from '../payment/payment-method.service';
import { switchMap } from 'rxjs/operators';
import { ModalService } from '../gui/modal/modal-service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimeoutAcceptedCashModalComponent } from 'src/app/components/general/timeout-accepted-cash-modal/timeout-accepted-cash-modal.component';

@Injectable()
export class LiteDisplayService {

  private dispatcherService: DispatcherService;
  private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService;
  private vuCommunicationService: VuCommunicationService;
  private demoPaymentService: DemoPaymentService;
  private paymentsMethodService: PaymentsMethodService;
  private configurationChangedSubscription: Subscription;
  private _paymentMethodServiseInitializeSubscription: Subscription;
  private _acceptedCoins: Money[];
  private _acceptedBills: Money[];
  private _acceptedCreditCards: CreditCard[];
  private _reloadPaymentsSubscription: Subscription;
  private modalService: ModalService;
  private modalRef: BsModalRef;

  eventRightButtonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    injector: Injector,
    private saleService: SaleService,
    private liteDisplayStepsService: LiteDisplayStepsService,
  ) {
    this.dispatcherService = injector.get(DispatcherService);
    this.additionalPropertiesConfigurationService = injector.get(AdditionalPropertiesConfigurationService);
    this.vuCommunicationService = injector.get(VuCommunicationService);
    this.demoPaymentService = injector.get(DemoPaymentService);
    this.paymentsMethodService = injector.get(PaymentsMethodService);
    this.modalService = injector.get(ModalService);
  }

  start() {
    this._unsubscribe();
    this.configurationChangedSubscription = this.dispatcherService.onConfigurationChangedSubscribe(() => this.onConfigurationChanged());
    this._paymentMethodServiseInitializeSubscription = this.paymentsMethodService.initialize(false)
      .subscribe(() => {
        this.onConfigurationChanged();
      });
    this.reloadTurnstileSide();
  }

  stop() {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    if (this.configurationChangedSubscription) {
      this.configurationChangedSubscription.unsubscribe();
      this.configurationChangedSubscription = null;
    }
    if (this._paymentMethodServiseInitializeSubscription) {
      this._paymentMethodServiseInitializeSubscription.unsubscribe();
      this._paymentMethodServiseInitializeSubscription = null;
    }
    this._reloadPaymentsUnSubscription();
  }

  private _reloadPaymentsUnSubscription(): void {
    if (this._reloadPaymentsSubscription) {
      this._reloadPaymentsSubscription.unsubscribe();
      this._reloadPaymentsSubscription = null;
    }
  }

  private onConfigurationChanged(): void {
    this._reloadPaymentsUnSubscription();
    this._reloadPaymentsSubscription = this.reloadPayments()
      .subscribe(__ => {
        this._reloadPaymentButtonsVisible();
        this.reloadTurnstileSide();
      });
  }

  reloadPayments(): Observable<boolean> {
    return of(null)
      .pipe(
        switchMap(__ => {
          return this.reloadAcceptedCash();
        }),
        switchMap(__ => {
          return this.reloadAcceptedCards();
        }),
      )
  }

  private _reloadPaymentButtonsVisible() {
    if (this.liteDisplayStepsService.isPaymentActive) {
      return;
    }
    this.liteDisplayStepsService.setStepsStates(
      StepConfigurationType.Amount,
      null,
      [
        this._isCashPaymentAvailable ? StepConfigurationType.Coins : null,
        StepConfigurationType.Amount,
        this._isCashlessPaymentAvailable ? StepConfigurationType.Card : null
      ],
      null
    )
  }

  reloadAcceptedCash(): Observable<boolean> {
    if (this.additionalPropertiesConfigurationService.isDemoMode) {
      this._acceptedCoins = this.demoAcceptedCoins;
      this._acceptedBills = this.demoAcceptedBills;
      return of(true);
    }
    if (!this.paymentsMethodService.isCashPaymentAvailable) {
      this._acceptedCoins = [];
      return of(true);
    }
    return from(this.vuCommunicationService.vuHttp.getAcceptedCash(this.orderAmount))
      .pipe(
        switchMap(acceptedCash => {
          if (acceptedCash && this.paymentsMethodService.isCashPaymentAvailable) {
            this._acceptedCoins = acceptedCash.coins ? acceptedCash.coins : [];
            this._acceptedBills = acceptedCash.banknotes ? acceptedCash.banknotes : [];
          } else {
            this._acceptedCoins = [];
            this._acceptedBills = [];
          }
          return of(true);
        })
      );
  }

  reloadAcceptedCards(): Observable<boolean> {
    if (this.additionalPropertiesConfigurationService.isDemoMode) {
      this._acceptedCreditCards = [
        new CreditCard('EC'),
        new CreditCard('Maestro'),
        new CreditCard('MasterCard'),
        new CreditCard('Apple'),
        new CreditCard('GPay'),
      ];
      return of(true);
    }
    if (!this._isCashlessPaymentAvailable) {
      this._acceptedCreditCards = [];
      return of(true);
    }
    return from(this.vuCommunicationService.vuHttp.getAcceptedCreditCards()).pipe(
      switchMap(acceptedCreditCards => {
        if (acceptedCreditCards) {
          this._acceptedCreditCards = this._isCashlessPaymentAvailable ? acceptedCreditCards : [];
        } else {
          this._acceptedCreditCards = [];
        }
        return of(true);
      })
    )
  }

  reloadTurnstileSide() {
    const entryLeftStep = this.liteDisplayStepsService.findStep(StepConfigurationType.EntryLeft);
    const entryRightStep = this.liteDisplayStepsService.findStep(StepConfigurationType.EntryRight);
    if (this.additionalPropertiesConfigurationService.turnstileIsLeftSide) {
      if (!entryLeftStep.visible) {
        entryLeftStep.visible = true;
      }
      if (entryRightStep.visible) {
        entryRightStep.visible = false;
      }
    } else {
      if (entryLeftStep.visible) {
        entryLeftStep.visible = false;
      }
      if (!entryRightStep.visible) {
        entryRightStep.visible = true;
      }
    }

    if (
      this.liteDisplayStepsService.currentStepType === StepConfigurationType.EntryLeft ||
      this.liteDisplayStepsService.currentStepType === StepConfigurationType.EntryRight
    ) {
      this.go();
    }
  }

  reloadAmount() {
    this.liteDisplayStepsService.setAmount(this.currentAmount);
  }

  showPayinCoins() {
    this.liteDisplayStepsService.setStepsStates(
      StepConfigurationType.Amount,
      [StepConfigurationType.Coins],
      [StepConfigurationType.Coins, StepConfigurationType.Amount],
      null
    )
  }

  saveOrder() {
    this.liteDisplayStepsService.selectAndActivate(StepConfigurationType.SaveOrder)
  }

  printTicket() {
    this.liteDisplayStepsService.selectAndActivate(StepConfigurationType.Print)
  }

  changeCoins() {
    this.liteDisplayStepsService.selectAndActivate(StepConfigurationType.Change)
  }

  go() {
    if (this.additionalPropertiesConfigurationService.turnstileIsLeftSide) {
      this.liteDisplayStepsService.selectAndSuccess(StepConfigurationType.EntryLeft);
    } else {
      this.liteDisplayStepsService.selectAndSuccess(StepConfigurationType.EntryRight);
    }
  }

  takeAndGo() {
    this.liteDisplayStepsService.selectAndSuccess(StepConfigurationType.TakePrintedStuff);
  }

  rfidAccessDenied() {
    this.liteDisplayStepsService.selectAndError(StepConfigurationType.RFID);
  }

  get acceptedCoins(): Money[] {
    return this._acceptedCoins;
  }

  get acceptedBills(): Money[] {
    return this._acceptedBills;
  }

  get acceptedCards(): CreditCard[] {
    return this._acceptedCreditCards;
  }

  get banner(): BannerConfiguration {
    return null;
  }

  updateAmountToPay(amountToPay: Money) {
    this.liteDisplayStepsService.setAmount(amountToPay);
  }

  reset() {
    this.liteDisplayStepsService.setStepsStates(
      StepConfigurationType.Amount,
      [StepConfigurationType.Amount],
      [null, StepConfigurationType.Amount, null],
      null
    )
    this.onConfigurationChanged();
  }

  onCoinsClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    if (currentStep != StepConfigurationType.Amount) {
      return;
    }

    if (this.liteDisplayStepsService.isPayByCoins && this.additionalPropertiesConfigurationService.isDemoMode) {
      this.demoPayment();
    } else {
      this.leftButtonClick();
    }
  }

  onCardsClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    if (currentStep != StepConfigurationType.Amount) {
      return;
    }

    if (this.liteDisplayStepsService.isPayByCard && this.additionalPropertiesConfigurationService.isDemoMode) {
      this.demoPayment();
    } else {
      this.rightButtonClick();
    }
  }

  leftButtonClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
      case StepConfigurationType.EntryLeft:
      case StepConfigurationType.EntryRight:
      case StepConfigurationType.TakePrintedStuff:
        this._backButtonEvent();
        break;
    }
  }

  rightButtonClick() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
        this.eventRightButtonClick.emit();
        break;
    }
  }

  get orderAmount(): Money {
    return this.saleService.order.amountTotal.clone();
  }

  get currentAmount(): Money {
    let money = this.orderAmount;
    money.value = money.value - this.saleService.order.amountReceived.value;
    return money;
  }

  showLeftButton() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
      case StepConfigurationType.EntryLeft:
      case StepConfigurationType.EntryRight:
      case StepConfigurationType.TakePrintedStuff:
        return true;
      default:
        return false;
    }
  }

  showRightButton() {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    switch (currentStep) {
      case StepConfigurationType.Amount:
        return !this.liteDisplayStepsService.isPaymentActive && this._isCashlessPaymentAvailable;
      case StepConfigurationType.Change:
        return !this.saleService.paymentSession.isCancelled;
      case StepConfigurationType.Print:
        return this.additionalPropertiesConfigurationService.isDemoMode;
      default:
        return false;
    }
  }

  _backButtonEvent() {
    this.dispatcherService.onBackButtonClick();
  }

  activePaymentByCoins() {
    this.liteDisplayStepsService.activeStep(StepConfigurationType.Coins, true);
  }

  activePaymentByCard() {
    this.liteDisplayStepsService.activeStep(StepConfigurationType.Card, true);
  }

  private demoPayment() {
    this.demoPaymentService.onDenominationClick();
  }

  get isCoinsVisible(): boolean {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    return currentStep === StepConfigurationType.Amount && !this.liteDisplayStepsService.isPayByCard;
  }

  get isBillsVisible(): boolean {
    return this.isCoinsVisible;
  }

  get isCardsVisible(): boolean {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    return currentStep === StepConfigurationType.Amount
      && !this.liteDisplayStepsService.isPayByCoins
      && this._isCashlessPaymentAvailable;
  }

  get availableCashlessPayments(): PaymentMethod[] {
    return this.paymentsMethodService.availablePaymentMethods;
  }

  private get _isCashlessPaymentAvailable(): boolean {
    return this.paymentsMethodService.availablePaymentMethods != null && this.paymentsMethodService.availablePaymentMethods.length > 0;
  }

  private get _isCashPaymentAvailable(): boolean {
    return this._acceptedCoins != null && this._acceptedCoins.length > 0;
  }

  private get demoAcceptedCoins(): Money[] {
    return [
      new Money(0.10, 'EUR'),
      new Money(0.20, 'EUR'),
      new Money(0.50, 'EUR'),
      new Money(1.00, 'EUR'),
      new Money(2.00, 'EUR')
    ];
  }

  private get demoAcceptedBills(): Money[] {
    return [
      new Money(5.00, 'EUR'),
      new Money(10.00, 'EUR'),
      new Money(20.00, 'EUR'),
      new Money(50.00, 'EUR')
    ];
  }

  get isTeaserVisible(): boolean {
    const currentStep = this.liteDisplayStepsService.currentStepType;
    return currentStep === StepConfigurationType.TakePrintedStuff;
  }

  get isPayByCoins(): boolean {
    return this.liteDisplayStepsService.isPayByCoins;
  }

  showAvailableCashPopup(): void {
    if (this.modalRef) {
      return;
    }
    this.modalRef = this.modalService.show(
      TimeoutAcceptedCashModalComponent,
      {
        acceptedCoins: this._acceptedCoins,
        acceptedBills: this._acceptedBills,
        autoCloseTimeout: 4000,
      },
      () => {
        this.modalRef = null;
      }
    );
  }

  hideAvailableCashPopup(): void {
    if (this.modalRef) {
      this.modalService.close(this.modalRef);
    }
  }

}
