import { Subject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message, MessageService, MessageType } from './message.service';
import { Product, Order, Money, OrderType, PaymentMethod, PaymentSession } from '../lib/lib';
import { IVuConnection } from './vu/connection/vu-connection.interfaces';
import { IVuHttp } from './vu/http/vu-http.interface';
import { LoggingService } from './logging/logging.service';
import { DispatcherService } from './dispatcher.service';
import { VuCommunicationService } from './vu/vu-communication.service';
import { TicketParameters } from '../lib/ticket/ticket-parameters';
import { PaymentMessageStore } from '../lib/payment/payment-message-store';
import { RemotePaymentTransaction } from '../lib/remote-payment-transaction';
import { DisplayItem } from '../lib/display/display-item/display-item';
import { CardInformation } from '../modules/recharge-card/models/card-information';
import { ProductValidatorService } from './product/product-validator.service';
import { ProductValidationInfo } from '../lib/product/product-validation-info';
import { ConfigurationService } from './configuration/configuration.service';

@Injectable()
export class SaleService {
  private internalOrder: Order;
  private vuConnection: IVuConnection;
  private vuHttp: IVuHttp;
  private log: LoggingService;
  private dispatcherService: DispatcherService;
  private vuCommunicationService: VuCommunicationService;
  private configurationService: ConfigurationService;
  protected messageService: MessageService;
  eventAmountToPayReached: Subject<Money> = new Subject();
  eventMoneyChanged: Subject<Money> = new Subject();
  cashlessPaymentMessageStore = new PaymentMessageStore();
  private internalRemoteTransaction: RemotePaymentTransaction;

  private _cardInformation: CardInformation;
  private _validationSubscription: Subscription;

  private _orderUpdating: boolean;

  constructor(
    protected injector: Injector,
    private productValidatorService: ProductValidatorService,
  ) {
    this.log = this.injector.get(LoggingService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
    this.configurationService = this.injector.get(ConfigurationService);
    this.messageService = this.injector.get(MessageService);
    this.vuConnection = this.vuCommunicationService.vuConnection;
    this.vuHttp = this.vuCommunicationService.vuHttp;
    this.internalRemoteTransaction = new RemotePaymentTransaction(this.vuHttp, this.log);
    this.resetOrder();
    this.dispatcherService.onVisualItemLeafSelectedSubscribe(x => this.onVisualItemLeafSelected(x));
    this.vuConnection.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.messageService.getSubscription().subscribe(message => {
      if (message && message.messageType === MessageType.MachineTimeoutModalCancel) {

        this.productValidatorService.modalForceClose();
        this.unsubscribeValidationSubscription();
      }
      if (message
        &&
        (
          message.messageType === MessageType.Back
          || message.messageType === MessageType.ButtonBackClicked
          || message.messageType === MessageType.MachineHardReset
        )) {
        this.unsubscribeValidationSubscription();
      }
    });
  }

  get remoteTransaction(): RemotePaymentTransaction {
    return this.internalRemoteTransaction;
  }

  unsubscribeValidationSubscription(): void {
    if (this._validationSubscription) {
      this._validationSubscription.unsubscribe();
      this._validationSubscription = null;
    }
  }

  onVisualItemLeafSelected(message: Message): void {
    if (!message || !message.info) {
      return;
    }
    let productPrice: Money = null;
    let product = message.info.data as Product;
    if (!product) {
      const displayItem = message.info as DisplayItem;
      if (displayItem) {
        product = displayItem.product;
        productPrice = displayItem.productPrice;
      }
    }

    if (!Product.isProduct(product) || this.productValidatorService.validationInProgress) {
      return;
    }

    this._orderUpdating = true;

    const defaultPriceListId = this.configurationService.configuration.defaultPriceListId;

    this.unsubscribeValidationSubscription();
    this._validationSubscription = this.productValidatorService.validateProduct(product)
      .subscribe((productValidationInfo: ProductValidationInfo) => {
        if (!productValidationInfo) {
          this._orderUpdating = false;
          return;
        }
        if (productValidationInfo.goBack) {
          this.dispatcherService.onBackButtonClick();
          this._orderUpdating = false;
        } else {
          this._cardInformation = productValidationInfo.cardInformation;
          this._addProduct(
            productValidationInfo.product,
            productPrice,
            productValidationInfo.additionalProducts,
            productValidationInfo.barcode,
            productValidationInfo.ticketParameters,
            productValidationInfo.priceListId || defaultPriceListId,
          );
        }
      });
  }

  private _addProduct(
    product: Product,
    productPrice: Money,
    additionalProducts: Product[],
    barcode: string,
    ticketParameters: TicketParameters = null,
    priceListId = 0,
  ): void {
    if (product) {
      const line = this.order.addProduct(product, 1, productPrice, barcode, ticketParameters);
      if (line.minQuantity === 0 && additionalProducts && additionalProducts.length !== 0) {
        line.minQuantity = 1;
      }
    }

    if (barcode) {
      const requestedBarcode = !product || product.scanCardRequested ? barcode : null;
      this.addAdditionalProductToOrder(additionalProducts, priceListId, requestedBarcode);
    } else {
      this.addAdditionalProductToOrder(additionalProducts, priceListId);
    }
  }

  addAdditionalProductToOrder(additionalProducts: Product[], priceListId: number, requestedBarcode: string = null): void {
    if (!additionalProducts || !additionalProducts.length) {
      this.applyPriceList(priceListId).then(() => {
        this.addProductsComplete();
      });
      return;
    }
    for (const item of additionalProducts) {
      let quantity = 0;
      if (item.additionalProperties && item.additionalProperties.qty_min) {
        quantity = item.additionalProperties.qty_min;
      }
      const barcode = item.scanCardRequested || item.isCard ? requestedBarcode : null;
      const orderLine = this.order.addProduct(item, quantity, item.customPrice, barcode);
      orderLine.required = true;
    }
    this.applyPriceList(priceListId).then(() => {
      this.addProductsComplete();
    });
  }

  addProductsComplete(): void {
    this._orderUpdating = false;
    if (this.applyGiftCardPayment()) {
      this.vuHttp.getCardPaymentInformation(this.order.giftCardName).toPromise().then(() => {
      });
    }
    this.dispatcherService.addProductsComplete();
  }

  applyGiftCardPayment(): boolean {
    if (
      !this.order
      || !this.cardInformation
      || !this.cardInformation.allowAutoPayment
      || !this.isPaymentMethodAvailable(PaymentMethod.GiftCard)
    ) {
      return false;
    }

    this.order.giftCardEan13 = this.cardInformation.ean13;
    this.order.giftCardName = this.cardInformation.name;
    this.order.giftCardPartialPaymentAmount = Math.min(this.order.amountTotal.value, this.cardInformation.balance);
    return true;
  }

  isPaymentMethodAvailable(paymentMethod: PaymentMethod): boolean {
    return this.configurationService.configuration
      && this.configurationService.configuration.paymentMethods
      && this.configurationService.configuration.paymentMethods.some(x => x === paymentMethod);
  }

  applyPriceList(priceListId: number): Promise<void> {
    if (!priceListId) {
      return Promise.resolve();
    }

    const productsInfo = this.order.orderLines.map(orderLine => ({ productId: orderLine.productId, quantity: orderLine.quantity }));
    return new Promise((resolve, reject) => {
      this.vuHttp.calculateProductsPrice(productsInfo, priceListId)
        .then(prices => {

          if (prices && prices.length === this.order.orderLines.length) {
            for (let i = 0; i < this.order.orderLines.length; i++) {
              let price = prices[i];
              const orderLine = this.order.orderLines[i];
              if (orderLine.product.customPrice) {
                price = orderLine.product.customPrice.value;
              }
              orderLine.setCustomPriceValue(price);
            }
          }

          resolve();
        });
    });
  }

  onMoneyChanged(money: Money): void {
    this.log.info(`SaleService. onMoneyChanged. '${money}'`);
    this.order.registerMoney(money);
    this.eventMoneyChanged.next(money);
    this.dispatcherService.onUserActivity();
  }

  resetOrder(): void {
    this.log.info('SaleService. Order reset.');
    this.internalOrder = new Order();
    this.dispatcherService.shopStateOrderUid(this.order.uid);
    this.remoteTransaction.reset();
    this._cardInformation = null;
  }

  ticketReturnAmount(
    product: Product, amount: Money,
    orderId: number, barcode: string,
    isDisplayMode: boolean,
    depositProduct: Product = null,
    cardInformation: CardInformation = null,
  ): void {
    if (!isDisplayMode) {
      this.resetOrder();
    }
    const order = this.createRefundOrder(product, amount, orderId, barcode, depositProduct, cardInformation);
    this.dispatcherService.ticketReturnAmount(isDisplayMode);
  }

  createRefundOrder(
    product: Product, amount: Money, orderId: number, barcode: string,
    depositProduct: Product, cardInformation: CardInformation): Order {
    this.log.info(`SaleService. createRefundOrder. product. ${product}`);
    this.log.info(`SaleService. createRefundOrder. amount: ${amount}. orderId: ${orderId}. barcode: ${barcode}.`);
    const order = this.order;
    if (orderId) {
      order.originalOrderId = orderId;
    }
    order.type = OrderType.SaleRefund;
    const negateAmount = amount.negate();
    const orderLine = order.addProduct(product, 1, negateAmount, barcode);
    const cardBalance = cardInformation?.balance;
    if (cardBalance != null) {
      orderLine.updateProperties('card_balance', cardBalance);
    }

    if (product.subProducts && !cardInformation) {
      product.subProducts.forEach(subProduct => {
        order.addProduct(subProduct, 1, subProduct.price.negate());
      });
    }

    if (cardInformation && depositProduct) {
      const depositOrderLine = order.addProduct(depositProduct, 1, depositProduct.price.negate());
      depositOrderLine.updateProperties('rfid_card_ean13', cardInformation.ean13);
      depositOrderLine.updateProperties('original_line_id', cardInformation.depositOrderLineId);
      depositOrderLine.updateProperties('is_return_card', true);
    }

    if (!cardInformation) {
      orderLine.updateProperties('rfid-refund', true);
    }

    this.log.info(`SaleService. createRefundOrder. order. ${order}`);
    return order;
  }

  get order(): Order {
    return this.internalOrder;
  }

  async openPaymentSession(paymentMethod: PaymentMethod, amount?: Money): Promise<void> {
    this.log.info(`SaleService. openPaymentSession. paymentMethod: '${PaymentMethod[paymentMethod]}'. amount: '${amount}'.`);
    this.order.paymentMethod = paymentMethod;
    await this.remoteTransaction.openRemoteTransaction(paymentMethod, amount, false, this.order.giftCardName, this.order.amountGiftPartial);
    this.order.openSession();
  }

  openPaymentSessionWithFloatingAmount(paymentMethod: PaymentMethod, minAmount: Money, maxAmount: Money): Promise<void> {
    this.log.info('SaleService. openPaymentSessionWithFloatingAmount.' +
      `paymentMethod: '${PaymentMethod[paymentMethod]}'. minAmount: '${minAmount}'. maxAmount: '${maxAmount}'.`);
    this.order.paymentMethod = paymentMethod;
    const amount = maxAmount;
    return this.remoteTransaction.openRemoteTransaction(
      paymentMethod, amount, true, this.order.giftCardName, this.order.amountGiftPartial).then(() => {
        this.order.openSession(minAmount, maxAmount);
      });
  }

  closePaymentSession(): void {
    this.order.closeSession();
    this.resetOrder();
  }

  get paymentSession(): PaymentSession {
    return this.order.paymentSession;
  }

  get orderId(): number {
    return this.order == null ? -1 : this.order.id;
  }

  addRfidCardBarcodeInOrder(barcode: string): void {
    if (this.order.hasRfidCard) {
      this.order.addRfidCardBarcode(barcode);
    } else {
      this.log.warning('addRfidCardBarcodeInOrder. Current order doesn`t contain RFID card');
    }
  }

  get buyOrderInOneClick(): boolean {
    if (!this.order || this.order.orderLines.length !== 1 || !this.order.orderLines[0].product) {
      return false;
    }

    return this.order.orderLines[0].product.buyInOneClick;
  }

  get buyOrderInOneClickJournal(): string {
    if (!this.order || this.order.orderLines.length !== 1 || !this.order.orderLines[0].product) {
      return '';
    }

    return this.order.orderLines[0].product.buyInOneClickJournal;
  }

  get orderInProgress(): boolean {
    return this.order && this.order.orderLines && this.order.orderLines.length !== 0;
  }

  get cardInformation(): CardInformation {
    return this._cardInformation;
  }

  get orderUpdating(): boolean {
    return this._orderUpdating;
  }
}
