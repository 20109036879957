import { Product } from './lib';
import { ILog } from '../services/logging/log.interface';
import { TranslatePropertyHelper } from './languages/translation-properties-helper';
import { BaseVisualItem } from './visual-item/base-visual-item';

export class VisualItem extends BaseVisualItem {
  id: number;
  private internalName: string;
  sequence: number;
  children: VisualItem[];
  cssClass: string;
  data: any;

  get name(): string {
    return this.internalName;
  }

  set name(value: string) {
    this.name = value;
  }

  get translations(): any {
    if (!this.data) {
      return null;
    }
    return this.data.translations;
  }

  get product(): Product {
    return this.data as Product;
  }

  static fromJSONObject(jsonObject: any, type: string, log: ILog): VisualItem {
    try {
      return this.fromJSONObjectImpl(jsonObject, type, log);
    } catch (e) {
      let json: any = null;
      try { json = JSON.stringify(jsonObject); } catch (e) { }
      log.error(`VisualItem. '${e.message}' jsonObject: '${json}'`);
      return null;
    }
  }

  private static fromJSONObjectImpl(jsonObject: any, type: string, log: ILog): VisualItem {
    const item = new VisualItem(
      jsonObject.id,
      jsonObject.name,
      jsonObject.sequence,
      jsonObject.children,
      jsonObject.data,
    );

    if (item.children && item.children.length > 0) {
      {

        const children: VisualItem[] = [];
        for (const child of item.children) {
          const c = VisualItem.fromJSONObject(child, type, log);
          if (c != null) {
            children.push(c);
          }
        }
        children.sort((a, b) => a.sequence - b.sequence);
        item.children = children;
      }
    } else {
      if (item.data) {
        item.data = VisualItem.convertDataToType(item.data, type, log);
      }
    }

    return item;
  }

  private static convertDataToType(data: any, type: string, log: ILog): any {
    let result: any = null;
    switch (type) {
      case 'store':
        result = Product.fromJson(data);
        break;
      case 'info':
        result = data;
        break;
      default:
        break;
    }

    if (result == null) {
      throw new Error(`Failed to parse data for '${type}'`);
    }
    return result;
  }

  static fromJson(data: any): VisualItem {
    if (data == null) {
      return null;
    }

    const result = new VisualItem(
      data.id,
      data.name,
      data.sequence,
      data.children,
      data.data,
    );

    if (data.children != null) {
      result.children = data.children.map(item => {
        return VisualItem.fromJson(item);
      });
    }

    return result;
  }

  static fromJsonArray(data: any): VisualItem[] {
    if (data == null) {
      return null;
    }
    return data.map(item => {
      return VisualItem.fromJson(item);
    });
  }

  productName(language: string) {
    const product = this.product;
    if (this.data == null || !Product.isProduct(product)) {
      return '';
    }

    return product.translateName(language) || product.name;
  }

  formattedCategoryDescription(language: string) {
    const categoryDescription = this.data != null && this.data.category_description ? this.data.category_description : '';
    if (categoryDescription && this.translations) {
      const translatePropertyHelper = new TranslatePropertyHelper(this.translations);
      return translatePropertyHelper.getTranslationByFieldName(language, 'category_description', categoryDescription);
    }
    return categoryDescription;
  }

  get imagePath() {
    const product = this.product;
    if (this.data != null && Product.isProduct(product)) {
      return `/../../static/images/info/product_${product.id}.jpg`;
    }

    return '';
  }

  get hasImage() {
    const product = this.product;
    if (this.data != null && Product.isProduct(product)) {
      return product.hasImage;
    }

    return false;
  }

  constructor(
    id: number,
    name: string,
    sequence: number,
    children: VisualItem[],
    data: any,
  ) {
    super();
    this.id = id;
    this.internalName = name;
    this.sequence = sequence;
    this.children = children;
    this.data = data;
  }

  isValidForAge(age: number): boolean {
    const product = this.product;
    if (product && Product.isProduct(product)) {
      return product.isValidForAge(age);
    }
    return true;
  }
}

export const VisualItemsApiName = 'visual-items';
