import { Subject } from 'rxjs';
import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../base/base-modal-component';
import { CashType, Money } from 'src/app/lib/lib';

@Component({
  moduleId: module.id,
  selector: 'timeout-accepted-cash-modal',
  templateUrl: 'timeout-accepted-cash-modal.component.html',
  styleUrls: ['timeout-accepted-cash-modal.component.scss'],
})
export class TimeoutAcceptedCashModalComponent extends BaseModalComponent {

  get cashTypeCoin(): CashType {
    return CashType.Coin;
  }

  get cashTypeBanknote(): CashType {
    return CashType.Banknote;
  }

  get acceptedCoins(): Money[] {
    return this.data && this.data.acceptedCoins ? this.data.acceptedCoins : [];
  }

  get acceptedBills(): Money[] {
    return this.data && this.data.acceptedBills ? this.data.acceptedBills : [];
  }
}
